import { gql } from '@apollo/client'

export const GET_USER_COMPANIES = gql`
  query getUserCompanies @api(name: "users")  {
    getUserCompanies{
      _id
      name
      rut
      dv
    }
  }
`

export const LOGIN_WITH_SELECTED_COMPANY = gql`
  mutation loginWithSelectedCompany($companyId: String!) @api(name: users) {
    loginWithSelectedCompany(companyId: $companyId) {
      access_token
      refresh_token
    }
  }
`
