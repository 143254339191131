import React from 'react'
import { Button, Col, Row } from 'antd'

import { PopularSearchProps } from '../../../types/component-types/PopularSearch.types'

import styles from './styles.module.css'

const PopularSearch: React.FC<PopularSearchProps> = ({
  popularCategories,
  stageHandler,
  faqHandler,
  popularSearchHandler,
}) => {
  return (
    <Row justify="center">
      <Col xs={24} className={styles.center}>
        {popularCategories.length === 0 ? null : (
          <>
            <p className={styles.titleFont}>Búsquedas populares</p>

            {popularCategories.map(category => {
              return (
                <Button
                  className={styles.categoryButton}
                  key={category.id}
                  onClick={() => {
                    stageHandler('popular')
                    popularSearchHandler(category.title)
                    faqHandler(category.id)
                  }}>
                  {category.title}
                </Button>
              )
            })}
          </>
        )}
      </Col>
    </Row>
  )
}

export default PopularSearch
