import React, { FC, useContext, useEffect, useState } from 'react'
import { MenuOutlined } from '@ant-design/icons'
import { useLazyQuery } from '@apollo/client'
import { Button, Col, Row, Skeleton } from 'antd'
import { Link } from 'gatsby'

import { RolesEnum } from '../../helpers/enums'
import { GET_PROFILE_INFO } from '../../queries/profile'
import { tokenInfo } from '../../services/auth'
import { SessionContext } from '../../services/session'
import CompanySelectionModal from '../CompanySelectionModal'
import { OptionsHeaderProps } from '../types'

import AdminMenu from './AdminMenu'
import { linkMenu } from './constant'
import DownArrow from './downarrow.inline.svg'
import FaqImage from './faq-image.inline.svg'
import MenuDrawer from './MenuDrawer'
import PasswordDrawer from './PasswordDrawer'
import ProfileDrawer from './ProfileDrawer'
import ProvisionalLogo from './provisionalLogo.inline.svg'

import styles from './styles.module.css'
import { getRedirectUrl } from '../../pages/inicio-sesion/log'
import { isPlatformUser } from '../../helpers/auth.helper'
import { MyProfile } from '../../queries/profile/queries';

const { Avatar } = Skeleton

const OptionsHeader: FC<OptionsHeaderProps> = ({
  backgroundStrip,
  fixedOnMobile,
  isHome,
  getCompanyInformation,
  getPercentDiscount,
}) => {
  const [selectionModal, setSelectionModal] = useState(false)
  const { setToken, setUser, setCompany, token, user,company } = useContext(SessionContext)
  const [profileDrawerVisibility, setProfileDrawerVisibility] = useState(false)
  const [passwordDrawerVisibility, setPasswordDrawerVisibility] =
    useState(false)
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false)
  // TODO: Ask why is it doing this query when there is a context set on the root of this app with same information.
  const [loadProfile, { data, loading }] = useLazyQuery(GET_PROFILE_INFO, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ myProfile }) => setCompany(myProfile.company),
  })


  const handlePasswordChange = () => {
    setProfileDrawerVisibility(false)
    setPasswordDrawerVisibility(true)
  }
  const handlePasswordDrawerRender = (value: boolean) => {
    setProfileDrawerVisibility(true)
    setPasswordDrawerVisibility(value)
  }
  let strip = <></>

  useEffect(() => {
    if (!data) loadProfile()
    if (!token && Object.keys(user).length < 1) {
      const { token, user } = tokenInfo(localStorage.getItem('token') ?? '')
      setUser(user)
      setToken(token)
    }
  }, [data])

  switch (backgroundStrip) {
    case 'short':
      strip = <div className={styles.short} />
      break
    case 'medium':
      strip = <div className={styles.medium} />
      break
    case 'long':
      strip = <div className={styles.long} />
      break
    default:
      break
  }

  const showDrawer = () => {
    setDrawerVisible(true)
  }

  const renderCompanyName = (name: string) => {
    if (!name) return ''
    if (name.length > 50) return `${name.substring(0, 50)}...`
    return name
  }

  const onClose = () => {
    setDrawerVisible(false)
  }

  const genAdminRedirectUrl = (token: string) => {
    const pathName = getRedirectUrl()

    return pathName
      ? `${process.env.GATSBY_ADMIN_URI}?token=${token}&pathName=${pathName}`
      : `${process.env.GATSBY_ADMIN_URI}?token=${token}`
  }

  const renderCompanySelection = () => (
    <Row justify="center" align="middle">
      <CompanySelectionModal
        selectionModal={selectionModal}
        setSelectionModal={setSelectionModal}
        loadProfile={loadProfile}
        getCompanyInformation={getCompanyInformation}
        getPercentDiscount={getPercentDiscount}
      />
      <Col className={styles.companyChangeName}>
        {renderCompanyName(data?.myProfile?.company?.name)}
      </Col>
      {isHome && (
        <Col className={styles.companyChangeCol}>
          <Button
            onClick={() => setSelectionModal(true)}
            className={styles.companyChangeButton}>
            <div className={styles.buttonText}>Cambiar</div>
          </Button>
        </Col>
      )}
    </Row>
  )

  const companySelectionConditional = () => {
    if (
      user.role === RolesEnum.MULTI_RUT_BUYER ||
      user.role === RolesEnum.MULTI_RUT_ADMIN
    )
      return renderCompanySelection()
    return null
  }
  return (
    <>
      <ProfileDrawer
        loading={loading}
        profile={data?.myProfile}
        visibility={profileDrawerVisibility}
        visibilityHandler={setProfileDrawerVisibility}
        handlePasswordChange={handlePasswordChange}
      />
      <PasswordDrawer
        visibility={passwordDrawerVisibility}
        visibilityHandler={handlePasswordDrawerRender}
      />
      <MenuDrawer drawerVisible={drawerVisible} onClose={onClose} />

      <div
        className={
          fixedOnMobile
            ? `${styles.menuBar} ${styles.fixedOnMobile} ${
                !backgroundStrip && styles.menuBarZIndex
              }`
            : `${styles.menuBar} ${!backgroundStrip && styles.menuBarZIndex}`
        }>
        <Row className={styles.headerContainer} align="middle">
          <Col xs={0} lg={3}>
            
          <a href={isPlatformUser(user.role) ? genAdminRedirectUrl(token) : getRedirectUrl() || '/app/inicio'}>
            <ProvisionalLogo
              className={(styles.placeholderIcon, styles.logoStyle)}
            />
          </a>

          </Col>
          <Col className={styles.modalColPadding}>
            { companySelectionConditional() }
          </Col>
          <Col xs={2} lg={0}>
            <Button type="primary" onClick={showDrawer}>
              <MenuOutlined style={{ fontSize: '150%' }} />
            </Button>
          </Col>
          <Col flex="auto">
            <Row justify="end">
              <Col xs={0} md={0} lg={20}>
                <Row justify="end">
                  {linkMenu.map(({ name, path, roles }, idx) =>
                    !path ? (
                      <AdminMenu
                        key={path + idx}
                        roles={[
                          RolesEnum.ADMIN_CCC,
                          RolesEnum.ADMIN_SCC,
                          RolesEnum.MULTI_RUT_ADMIN,
                        ]}>
                        <p className={styles.link}>{name}</p>
                      </AdminMenu>
                    ) : !roles ||
                      (roles && roles.includes(user.role as RolesEnum)) ? (
                      <Link className={styles.link} key={path + idx} to={path}>
                        {name}
                      </Link>
                    ) : null,
                  )}
                </Row>
              </Col>

              <div
                className={styles.initialsContainer}
                onClick={() => setProfileDrawerVisibility(true)}>
                {loading ? (
                  <Avatar
                    active
                    size="small"
                    className={styles.avatarSkeleton}
                  />
                ) : (
                  <p className={styles.initialsFont}>
                    {data?.myProfile?.firstName[0]}
                    {data?.myProfile?.lastName[0]}
                  </p>
                )}
              </div>
              <div
                className={styles.downArrow}
                onClick={() => setProfileDrawerVisibility(true)}>
                <DownArrow />
              </div>
            </Row>
          </Col>
        </Row>
        {strip}
        {backgroundStrip === 'medium' && (
          <FaqImage className={styles.faqStyles} />
        )}
      </div>
    </>
  )
}

export default OptionsHeader
