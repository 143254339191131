import React from 'react'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { Collapse } from 'antd'

import { Question } from '../../../../types/question.type'

import styles from './styles.module.css'

const { Panel } = Collapse
const FAQ: React.FC<Question> = ({ title, id, body }) => (
  <Collapse bordered={false}>
    <Panel
      header={<span className={styles.faqFont}>{title}</span>}
      key={id ?? ''}
      className={styles.title}>
      <div
        className={styles.faqContainer}
        dangerouslySetInnerHTML={{
          __html: documentToHtmlString(JSON.parse(body?.raw ?? '')),
        }}
      />
    </Panel>
  </Collapse>
)

export default FAQ
