export const useEventSave = (
  eventTitle: string,
  params: { [key: string]: string },
): void => {
  try {
    if (typeof window !== 'undefined')
      window.gtag('event', eventTitle, {
        ...params,
      })
    console.log('done')
  } catch (error) {
    //   console.log(error);
  }
}
