import React, { FC } from 'react'
import { useMutation } from '@apollo/client'
import { Button, Col, Form, Input, message, Row } from 'antd'

import { RECOVER_PASSWORD } from '../../../queries'

import styles from './styles.module.css'

const { Item } = Form

type props = {
  setShowRecoverForm(value: boolean): void
  email: string
}

const ForgetPasswordForm: FC<props> = ({ email, setShowRecoverForm }) => {
  const [recoverPasswordMutation, { loading }] = useMutation(RECOVER_PASSWORD)

  const recoverPassword = (email: string) => {
    try {
      recoverPasswordMutation({
        variables: {
          email,
        },
      })
      message.success(`Revisa tu correo para continuar con el proceso`)
      setShowRecoverForm(false)
    } catch (err) {
      console.log(err)
      message.error(err.message)
    }
  }

  return (
    <Row justify="center">
      <Col>
        <Form
          onFinish={values => recoverPassword(values.email)}
          initialValues={{
            email,
          }}>
          <p className={styles.alignLeft}>Correo</p>
          <Item
            name="email"
            rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
            <Input placeholder="Correo" className={styles.input} />
          </Item>

          <Button htmlType="submit" className={styles.button} loading={loading}>
            Recuperar Cuenta
          </Button>
          <Button onClick={() => setShowRecoverForm(false)}>Cancelar</Button>
        </Form>
      </Col>
    </Row>
  )
}

export default ForgetPasswordForm
