import { useLayoutEffect, useState } from 'react'

export const useScreenWidth = (): number => {
  const [screenWidth, setScreenWidth] = useState<number>(0)

  const updateWidth = () => {
    setScreenWidth(window?.innerWidth)
  }

  useLayoutEffect(() => {
    window?.addEventListener('resize', updateWidth)
    updateWidth()
    return () => window?.removeEventListener('resize', updateWidth)
  }, [])

  return screenWidth
}

export const useScreenFocus = (): boolean => {
  const [isFocused, setIsFocused] = useState<boolean>(true)

  useLayoutEffect(() => {
    document?.addEventListener('visibilitychange', () =>
      setIsFocused(!document.hidden),
    )
    return () =>
      window?.removeEventListener('visibilitychange', () =>
        setIsFocused(!document.hidden),
      )
  }, [])

  return isFocused
}
