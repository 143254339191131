import { gql } from '@apollo/client'

export const GET_PROFILE_INFO = gql`
  query @api(name: "users") {
    myProfile {
      role
      firstName
      lastName
      email
      phone
      createdAt
      company {
        creditLine {
          _id
        }
        name
      }
      seatsAllowed
    }
  }
`

export type GetProfileInfo = {
  myProfile: MyProfile
}

export type MyProfile = {
  company: {
    name: string
  }
  email: string
  firstName: string
  lastName: string
  phone: string
  role: string
  createdAt: Date
}

