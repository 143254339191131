import { RolesEnum } from '../../helpers/enums'

export const linkMenu = [
  {
    name: 'Inicio',
    path: '/app/inicio',
  },
  {
    name: 'Mis Viajes',
    path: '/app/mis-viajes',
  },
  {
    name: 'Mis Pasajeros',
    path: '/app/mis-pasajeros',
  },
  {
    name: 'Reportes',
    path: '/app/reportes',
  },
  {
    name: 'Facturas',
    path: '/app/facturas',
    roles: [RolesEnum.ADMIN_CCC, RolesEnum.MULTI_RUT_ADMIN],
  },
  {
    name: 'Administración',
    path: '',
    roles: [
      RolesEnum.ADMIN_CCC,
      RolesEnum.ADMIN_SCC,
      RolesEnum.MULTI_RUT_ADMIN,
    ],
  },
  {
    name: 'Solicitudes',
    path: '/app/mis-solicitudes',
  },
  {
    name: 'Ayuda',
    path: '/app/ayuda',
  },
]

export const adminMenu = [
  {
    name: 'Usuarios',
    path: '/app/mis-usuarios',
    type: 'ADMIN_CCC,ADMIN_SCC,MULTI_RUT_ADMIN',
  },
  {
    name: 'Centros de Costo',
    path: '/app/centros-de-costo',
    type: 'ADMIN_CCC,MULTI_RUT_ADMIN',
  },
]
