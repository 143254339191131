import React, { useEffect, useState } from 'react'
import { Col, Row } from 'antd'
import { navigate } from 'gatsby'

import OptionsHeader from '../../components/OptionsHeader'
import SEO from '../../components/seo'
import { useEventSave } from '../../hooks/analytics.hook'
import { getUser, isLoggedIn, tokenInfo } from '../../services/auth'
import { Question } from '../../types/question.type'

import DirectSupport from './DirectSupport'
import HelpDisplay from './HelpDisplay'
import HelpFooter from './HelpFooter'
import PopularSearch from './PopularSearch'
import SearchBar from './SearchBar'
import { props } from './types'

import styles from './styles.module.css'

const HelpPage: React.FC<props> = ({ pageContext: { categories, questions }}) => {
  const [stage, setStage] = useState('categories')
  const [popularSearch, setPopularSeach] = useState('')
  const [tagSearch, setTagSearch] = useState('')
  const [faqs, setFaqs] = useState<Question[]>([])

  useEffect(() => {
    const { user } = tokenInfo(getUser() ?? '')
    const params: { [key: string]: string } = {}
    params[user.email as string] = 'vista_de_ayuda' as string

    useEventSave('vista_de_ayuda', {
      email_usuarios: user.email,
    })

    useEventSave('paginas_por_usuario', params as { [key: string]: string })
  }, [])

  const checkUser = () =>
    !isLoggedIn() && location?.pathname !== `/inicio-sesion/`
      ? navigate('/')
      : null

  const filterFaqsByCategoryId = (categoryId: string) => {
    setFaqs([
      ...questions.filter(question =>
        question?.categories?.some(category => category.id === categoryId),
      ),
    ])
  }

  const filterByText = (searchValue: string) => {
    setFaqs([
      ...questions.filter(
        question =>
          question.body?.raw.includes(searchValue) ||
          question.title?.includes(searchValue),
      ),
    ])
  }

  useEffect(() => {
    checkUser()
  }, [])

  return (
    <Row className={styles.background}>
      <Col xs={24}>
        <SEO title="Ayuda" />
        <OptionsHeader backgroundStrip={'medium'} />
        <Row justify="center">
          <Col>
            <SearchBar
              stageHandler={setStage}
              faqHandler={filterByText}
              tagSearchHandler={setTagSearch}
            />
            <PopularSearch
              popularCategories={categories.filter(
                category => category.isPopular,
              )}
              stageHandler={setStage}
              faqHandler={filterFaqsByCategoryId}
              popularSearchHandler={setPopularSeach}
            />
            <HelpDisplay
              stage={stage}
              stageHandler={setStage}
              categories={categories}
              faqs={faqs}
              faqHandler={filterFaqsByCategoryId}
              popularSearchTerm={popularSearch}
              tagSearchTerm={tagSearch}
            />
            <DirectSupport />
          </Col>
        </Row>
        <HelpFooter />
      </Col>
    </Row>
  )
}
export default HelpPage
