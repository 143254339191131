import React, { FC, useEffect, useState } from 'react'
import { ApolloError, useMutation } from '@apollo/client'
import { Button, Col, Drawer, Form, Input, message, Row } from 'antd'

import { SuccessIcon } from '../../../assets'
import { PASSWORD_UPDATE } from '../../../queries/options-header'
import { PasswordDrawerProps, PasswordUpdateForm } from '../../types'

import styles from '../ProfileDrawer/styles.module.css'

const { Item } = Form
let checkWidth: () => number

const SuccessUpdate: FC = () => (
  <Row>
    <Col span={24} className={styles.successContainer}>
      <Row justify="space-around" className={styles.successIcon}>
        <SuccessIcon />
      </Row>

      <Row align="middle" justify="center">
        <b className={styles.successUpdateTitle}>Modificaste tu contraseña</b>
      </Row>

      <Row align="middle" justify="center">
        <p className={styles.successUpdateText}>
          Has modificado tu contraseña con éxito, podrás utilizarla la próxima
          vez que accedas al portal.
        </p>
      </Row>
    </Col>
  </Row>
)

const PasswordDrawer: FC<PasswordDrawerProps> = ({
  visibility,
  visibilityHandler,
}) => {
  const [validUpdate, setValidUpdate] = useState(false)
  const [updatePassword, { data, loading, error }] = useMutation(
    PASSWORD_UPDATE,
    {
      onCompleted: data => data?.updatePassword && setValidUpdate(true),
      onError: (error: ApolloError) => message.error(error.message),
    },
  )
  const onCancel = () => {
    setValidUpdate(false)
    visibilityHandler(false)
  }

  const onFinish = (values: PasswordUpdateForm) => {
    const { oldPassword, newPassword } = values
    updatePassword({
      variables: {
        input: {
          oldPassword,
          newPassword,
        },
      },
    })
  }

  useEffect(() => {
    checkWidth = () =>
      window?.innerWidth > 900 ? 400 : window?.innerWidth - 10
  }, [])

  return (
    <Drawer
      className={styles.drawer}
      headerStyle={{ textAlign: 'center' }}
      onClose={() => onCancel()}
      maskStyle={{ height: '100vh' }}
      placement="right"
      title="Modificar contraseña"
      visible={visibility}
      width={checkWidth ? checkWidth() : 400}>
      {validUpdate ? (
        <SuccessUpdate />
      ) : (
        <Form onFinish={onFinish}>
          <p className={styles.passLabelFont}>Contraseña actual</p>
          <Item
            className={styles.passwordInputContainer}
            name="oldPassword"
            rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
            <Input.Password
              className={styles.input}
              placeholder="Ejemplo: auyTKs259$."
            />
          </Item>

          <p className={styles.passLabelFont}>Nueva contraseña</p>
          <Item
            className={styles.passwordInputContainer}
            name="newPassword"
            rules={[
              { required: true, message: 'Este campo es obligatorio' },
              {
                pattern:
                  /(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*.?&#])[A-Za-z\d@$!%*.?&#]{8,}/,
                message: 'Introduzca una contraseña válida',
              },
            ]}>
            <Input.Password
              className={styles.input}
              placeholder="Ejemplo: auyTKs259$."
            />
          </Item>

          <p className={styles.passLabelFont}>Confirmar nueva contraseña</p>
          <Item
            className={styles.passwordInputContainer}
            name="repeatPassword"
            rules={[
              { required: true, message: 'Este campo es obligatorio' },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('newPassword') === value)
                    return Promise.resolve()

                  return Promise.reject('Las contraseñas no coinciden')
                },
              }),
            ]}>
            <Input.Password
              className={styles.input}
              placeholder="Ejemplo: auyTKs259$."
            />
          </Item>
          <p className={styles.passwordDescriptionFont}>
            Tu contraseña debe contener al menos 8 caracteres, incluyendo
            mayúsculas, minúsculas y un caracter especial. (Ej. @$!%*?&#.)
          </p>
          <Item>
            <Row
              align="middle"
              className={styles.drawerButtonRow}
              justify="center"
              gutter={25}>
              <Col span={12}>
                <Button
                  block
                  className={styles.cancelButton}
                  onClick={() => visibilityHandler(false)}>
                  {' '}
                  Cancelar
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  block
                  loading={loading}
                  className={styles.submitButton}
                  htmlType="submit">
                  Modificar
                </Button>
              </Col>
            </Row>
          </Item>
        </Form>
      )}
    </Drawer>
  )
}

export default PasswordDrawer
