import React from 'react'
import { Button, Col, Image, Row } from 'antd'

import { CategoryButtonProps } from '../../../../types/component-types/CategoryButton.types'

import styles from './styles.module.css'

const CategoryButton: React.FC<CategoryButtonProps> = ({
  category,
  stageHandler,
  faqHandler,
  verticalStyle,
  activeButton,
  setActiveButton,
  hideButtonTexts,
}) => {
  return (
    <Button
      className={`${styles.button} ${
        verticalStyle ? styles.verticalButton : styles.roundedButton
      } ${
        activeButton === category.id && verticalStyle
          ? styles.activeButton
          : null
      }`}
      onClick={() => {
        stageHandler('faq')
        faqHandler(category.id)
        setActiveButton && setActiveButton(category.id)
      }}>
      <Row align="middle" justify="start" gutter={10}>
        <Col>
          {category.icon?.file?.url ? (
            <Image
              src={`${category.icon?.file?.url}`}
              width={25}
              height={25}
              preview={false}
            />
          ) : null}
        </Col>
        {!hideButtonTexts && <Col>{category.title}</Col>}
      </Row>
    </Button>
  )
}

export default CategoryButton
