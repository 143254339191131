import React, { useState } from 'react'
import { Card, Col, Empty, Row } from 'antd'

import BackArrow from '../../../components/BackArrow'
import { useScreenWidth } from '../../../hooks/screen.hook'
import { HelpDisplayProps } from '../../../types/component-types/HelpDisplay.types'
import { Question } from '../../../types/question.type'

import CategoryButton from './CategoryButton'
import FAQ from './FAQ'

import styles from './styles.module.css'

const HelpDisplay: React.FC<HelpDisplayProps> = ({
  stage,
  stageHandler,
  categories,
  faqs,
  faqHandler,
  popularSearchTerm,
  tagSearchTerm,
}) => {
  const [activeButton, setActiveButton] = useState<string>()
  const width = useScreenWidth()
  return (
    <Card className={styles.card}>
      <Row justify="center">
        <Col xs={24}>
          {stage === 'categories' && (
            <>
              <p className={styles.categoriesTitleFont}>
                Elige una categoría para resolver tus dudas
              </p>
              <Row gutter={10}>
                {categories.map(category => {
                  return (
                    <Col xs={24} md={12} className={styles.paddingY02}>
                      <CategoryButton
                        setActiveButton={setActiveButton}
                        category={category}
                        stageHandler={stageHandler}
                        faqHandler={faqHandler}
                        key={category.id}
                      />
                    </Col>
                  )
                })}
              </Row>
            </>
          )}

          {stage === 'faq' && (
            <>
              <BackArrow
                clickAction={() => {
                  stageHandler('categories')
                }}
              />
              <p className={styles.faqTitleFont}>Categoría de preguntas</p>
              {
                <Row>
                  <Col xs={5} md={8}>
                    {categories.map(category => (
                      <CategoryButton
                        activeButton={activeButton}
                        setActiveButton={setActiveButton}
                        verticalStyle={true}
                        hideButtonTexts={width < 760}
                        category={category}
                        stageHandler={stageHandler}
                        faqHandler={faqHandler}
                        key={category.id}
                      />
                    ))}
                  </Col>
                  <Col xs={19} md={16}>
                    {faqs?.length === 0 ? (
                      <div className={styles.empty}>
                        <Empty description={'No hay información'} />
                      </div>
                    ) : (
                      faqs?.map((faq: Question) => {
                        return (
                          <FAQ
                            title={faq.title}
                            id={faq.id}
                            key={faq.id}
                            body={faq.body}
                          />
                        )
                      })
                    )}
                  </Col>
                </Row>
              }
            </>
          )}

          {stage === 'popular' && (
            <>
              <Row justify="space-between">
                <Col>
                  <p className={styles.searchFaqTitleFont}>
                    {faqs?.length} resultado{faqs?.length === 1 ? null : 's'} de
                    búsqueda para “{popularSearchTerm}”
                  </p>
                </Col>

                <Col
                  className={styles.deleteSearchContainer}
                  onClick={() => {
                    stageHandler('categories')
                  }}>
                  <p className={styles.deleteSearchFont}>Borrar búsqueda</p>
                </Col>
              </Row>

              {faqs?.length === 0 ? (
                <div className={styles.empty}>
                  <Empty description={'No hay información'} />
                </div>
              ) : (
                faqs?.map((faq: Question) => {
                  return (
                    <FAQ
                      title={faq.title}
                      id={faq.id}
                      key={faq.id}
                      body={faq.body}
                    />
                  )
                })
              )}
            </>
          )}
          {stage === 'tag' && (
            <>
              <Row justify="space-between">
                <Col>
                  <p className={styles.searchFaqTitleFont}>
                    {faqs?.length} resultado
                    {faqs?.length === 1 ? null : 's'} de búsqueda para “
                    {tagSearchTerm}”
                  </p>
                </Col>

                <Col
                  className={styles.deleteSearchContainer}
                  onClick={() => {
                    stageHandler('categories')
                  }}>
                  <p className={styles.deleteSearchFont}>Borrar búsqueda</p>
                </Col>
              </Row>

              {faqs?.length === 0 ? (
                <Empty description={'No hay información'} />
              ) : (
                faqs?.map((faq: Question) => {
                  return (
                    <FAQ
                      title={faq.title}
                      id={faq.id}
                      key={faq.id}
                      body={faq.body}
                    />
                  )
                })
              )}
            </>
          )}
        </Col>
      </Row>
    </Card>
  )
}

export default HelpDisplay
