import React from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { Button, Col, Form, Input, message, Row } from 'antd'
import { ValidateErrorEntity } from 'rc-field-form/lib/interface'

import {
  SearchBarFormValues,
  SearchBarProps,
} from '../../../types/component-types/SearchBar.types'

import styles from './styles.module.css'

const SearchBar: React.FC<SearchBarProps> = ({
  stageHandler,
  faqHandler,
  tagSearchHandler,
}) => {
  const onFinish: (values: SearchBarFormValues) => void = values => {
    if (values.searchValue) {
      stageHandler('tag')
      tagSearchHandler(values.searchValue)
      faqHandler(values.searchValue)
    } else message.warning('Debe agregar un criterio de búsqueda')
  }

  const onFinishFailed: (
    errorInfo: ValidateErrorEntity<SearchBarFormValues>,
  ) => void = errorInfo => {
    console.log(errorInfo)
  }

  return (
    <Row className={styles.outerContainer} justify="center">
      <Col>
        <p className={styles.titleFont}>¿Necesitas ayuda?</p>
        <p className={styles.descriptionFont}>
          ¿Qué estás buscando? Permítenos ayudarte.
        </p>

        <Form
          className={styles.form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}>
          <Row gutter={10}>
            <Col xs={18}>
              <Form.Item name="searchValue">
                <Input
                  className={styles.searchInput}
                  placeholder="Buscar..."
                  prefix={<SearchOutlined />}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col xs={6}>
              <Form.Item>
                <Button
                  className={styles.searchButton}
                  htmlType="submit"
                  loading={false}>
                  Buscar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  )
}

export default SearchBar
