import React, { FC, useEffect } from 'react'
import { Button, Col, Drawer, Row, Skeleton } from 'antd'

import { RolesEnum } from '../../../helpers/enums'
import { logout } from '../../../services/auth'
import { ProfileDrawerProps } from '../../types'

import styles from './styles.module.css'

const { Avatar, Input } = Skeleton
let checkWidth: () => number

const ProfileDrawer: FC<ProfileDrawerProps> = ({
  loading,
  profile,
  visibility,
  visibilityHandler,
  handlePasswordChange,
}) => {
  useEffect(() => {
    checkWidth = () =>
      window?.innerWidth > 900 ? 400 : window?.innerWidth - 10
  }, [])

  return (
    <Drawer
      className={styles.drawer}
      headerStyle={{ textAlign: 'center' }}
      onClose={() => visibilityHandler(false)}
      maskStyle={{ height: '100vh' }}
      placement="right"
      title="Mi Perfil"
      visible={visibility}
      width={checkWidth ? checkWidth() : 400}>
      {profile?.role === RolesEnum.CCC ||
      (RolesEnum.MULTI_RUT_BUYER && !loading) ? (
        <p className={styles.cccFont}>Cuenta Corriente</p>
      ) : null}
      <Row>
        <Col className={styles.initialsContainer}>
          <Row
            align={'middle'}
            justify={'center'}
            className={styles.initialsRow}>
            {loading ? (
              <Avatar active size="large" className={styles.avatarSkeleton} />
            ) : (
              <Col className={styles.initialsFont}>
                {profile?.firstName[0]}
                {profile?.lastName[0]}
              </Col>
            )}
          </Row>
        </Col>
        <Col className={styles.userInfoContainer}>
          <Row className={styles.userInfoRow}>
            <Col>
              {loading ? (
                <Input active size="default" className={styles.nameSkeleton} />
              ) : (
                <>
                  <p className={styles.nameFont}>
                    {profile?.firstName} {profile?.lastName}
                  </p>
                  <p className={styles.companyFont}>{profile?.company?.name}</p>
                </>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <div className={styles.horizontalLine} />
      <Row gutter={40}>
        <Col span={8}>
          <p className={styles.labelFont}>Correo electrónico</p>
        </Col>
        <Col>
          <p className={styles.labelContentFont}>{profile?.email}</p>
        </Col>
      </Row>
      <Row align="middle">
        <Col span={8}>
          <p className={styles.labelFont}>Contraseña</p>
        </Col>
        <Col span={16}>
          <Row justify="space-between" align="middle">
            <Col span={10}>**********</Col>
            <Col span={10} onClick={handlePasswordChange}>
              <p style={{ cursor: 'pointer' }}>Modificar</p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row align="middle">
        <Col span={8}>
          <p className={styles.labelFont}>Teléfono</p>
        </Col>
        <Col span={16}>
          {loading ? (
            <Input active size="large" className={styles.textSkeleton} />
          ) : (
            <>
              <p className={styles.labelContentFont}>
                +{profile?.phone.substring(0, 2)} {profile?.phone.substring(2)}
              </p>
            </>
          )}
        </Col>
      </Row>
      <div className={styles.horizontalLine} />
      <Row justify="center" align="middle">
        <Col>
          <Button className={styles.button} onClick={() => logout()}>
            Cerrar sesión
          </Button>
        </Col>
      </Row>
    </Drawer>
  )
}

export default ProfileDrawer
