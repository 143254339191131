import React, { FC, useEffect, useState } from 'react'
import { Dropdown, Menu } from 'antd'
import { Link } from 'gatsby'

import { getUser, tokenInfo } from '../../services/auth'
import { TokenUser } from '../../types/user.type'

import { adminMenu } from './constant'

const { Item } = Menu

interface AdminMenuProps {
  roles: string[]
}

const AdminMenu: FC<AdminMenuProps> = ({ children, roles }) => {
  const [user, setUser] = useState<TokenUser>()

  const menu = (
    <Menu>
      {adminMenu.map(({ name, path, type }, idx) =>
        type.includes(user?.role ?? '') ? (
          <Item key={path + idx}>
            <Link to={path}>{name}</Link>
          </Item>
        ) : null,
      )}
    </Menu>
  )

  useEffect(() => {
    const { user } = tokenInfo(getUser() ?? '')
    setUser(user)
  }, [])

  return roles && roles.includes(user?.role ?? '') ? (
    <Dropdown overlay={menu} placement="bottomCenter">
      {children}
    </Dropdown>
  ) : null
}

export default AdminMenu
