import React, { FC, useContext, useEffect, useState } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Button, Col, Form, Input, message, Row } from 'antd'
import { navigate } from 'gatsby'
import { ValidateErrorEntity } from 'rc-field-form/lib/interface'

import { getToken, isPlatformUser } from '../../../helpers/auth.helper'
import { LOGIN_USER } from '../../../queries'
import { GET_PROFILE_INFO } from '../../../queries/profile'
import { cleanToken, tokenInfo } from '../../../services/auth'
import { SessionContext } from '../../../services/session'
import { LoginFormValues } from '../../../types/component-types/Login.types'
import ForgetPasswordForm from '../forgetPasswordForm'

import styles from './styles.module.css'

const { Item } = Form

export const getRedirectUrl = (): string | null => {

  if (typeof window === 'undefined') {
    return null
  }

  const urlParams = new URLSearchParams(window.location.search);

  return urlParams.get('pathName');
}

const genAdminRedirectUrl = (token: string) => {
  const pathName = getRedirectUrl()

  return pathName
    ? `${process.env.GATSBY_ADMIN_URI}?token=${token}&pathName=${pathName}`
    : `${process.env.GATSBY_ADMIN_URI}?token=${token}`
}

const Login: FC = () => {
  const { setToken, setUser } = useContext(SessionContext)
  const [login, { loading }] = useMutation(LOGIN_USER)
  const [myProfile] = useLazyQuery(GET_PROFILE_INFO, {
    onCompleted: () => {
      const userToken = getToken()
      const { token, user } = tokenInfo(userToken)

      if (isPlatformUser(user.role)) {
        const adminUrl = genAdminRedirectUrl(token)
        window.location.href = adminUrl
      } else {
        const returnUrl = getRedirectUrl()
        navigate(returnUrl ? returnUrl : '/app/inicio')
      }
    },
    onError: () => {
      cleanToken()
    },
  })

  const [showRecoverForm, setShowRecoverForm] = useState(false)
  const [email, setEmail] = useState('')

  const onFinish: (values: LoginFormValues) => void = async values => {
    try {
      const { data } = await login({
        variables: {
          email: values.username.trim().toLowerCase(),
          pass: values.password,
        },
      })

      // llevarse esto a una funcion y ocuparlo en company-selection success
      const { token, user } = tokenInfo(data?.login?.access_token ?? '')
      setUser(user)
      setToken(token)
      const adminUrl = genAdminRedirectUrl(token)
      if (isPlatformUser(user.role)) window.location.href = adminUrl
      else {
        const returnUrl = getRedirectUrl()
        navigate(returnUrl ? returnUrl : '/app/inicio')
      }
    } catch (err) {
      message.error(err.toString())
    }
  }

  const onFinishFailed: (
    errorInfo: ValidateErrorEntity<LoginFormValues>,
  ) => void = errorInfo => {
    console.log('Failed:', errorInfo)
  }

  const setEmailOnFormChange = (values: Record<string, string>) => {
    if (values.username) setEmail(values.username)
  }

  const cancelRecover = () => {
    setShowRecoverForm(false)
    setEmail('')
  }

  useEffect(() => {
    const userToken = getToken()
    if (userToken) {
      if (location.search.includes('logout')) {
        cleanToken()
        return
      }
      myProfile()
    }
  }, [])

  return (
    <Row justify="center" align="middle">
      <Col span={24} className={styles.centered}>
        {showRecoverForm ? (
          <ForgetPasswordForm
            email={email}
            setShowRecoverForm={cancelRecover}
          />
        ) : (
          <Form
            onValuesChange={values => setEmailOnFormChange(values)}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}>
            <Row>
              <Col xs={0} md={24}>
                <p className={styles.inputsLabels}>Correo</p>
              </Col>
            </Row>
            <Item
              className={styles.userNameInputContainer}
              name="username"
              rules={[
                { required: true, message: 'Este campo es obligatorio' },
              ]}>
              <Input className={styles.input} placeholder="Usuario" />
            </Item>
            <Row>
              <Col xs={0} md={24}>
                <p className={styles.inputsLabels}>Contraseña</p>
              </Col>
            </Row>
            <Item
              className={styles.passwordInputContainer}
              name="password"
              rules={[
                { required: true, message: 'Este campo es obligatorio' },
              ]}>
              <Input.Password
                className={styles.input}
                placeholder="Contraseña"
              />
            </Item>
            <Item>
              <div
                className={styles.forgotPasswordContainer}
                onClick={() => console.log('User forgot his password')}>
                <p
                  className={styles.forgotPasswordFont}
                  onClick={() => setShowRecoverForm(true)}>
                  ¿Olvidaste tu contraseña?
                </p>
              </div>
            </Item>
            <Item>
              <Button
                className={styles.loginButton}
                htmlType="submit"
                block
                loading={loading}>
                Iniciar sesión
              </Button>
            </Item>
          </Form>
        )}

        <Row
          align="middle"
          justify="space-around"
          className={styles.registerSeparation}>
          <Col span={6}>
            <div className={styles.line} />
          </Col>

          <Col>
            <p className={styles.registerSeparationFont}>
              ¿No te has registrado?
            </p>
          </Col>
          <Col span={6}>
            <div className={styles.line} />
          </Col>
        </Row>

        <Button
          className={styles.registerButton}
          onClick={() => {
            navigate('/app/registro')
          }}>
          Regístrate ahora
        </Button>
      </Col>
    </Row>
  )
}

export default Login
