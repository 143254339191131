import React from 'react'
import { Col, Row } from 'antd'

import { WhiteLogo } from '../../../assets/logo'

import styles from './styles.module.css'
const HelpFooter = (): React.ReactElement => {
  return (
    <Row className={styles.footerContainer} justify="center">
      <Col xs={24}>
        <WhiteLogo className={styles.whiteLogo} />
        {/* <Row justify="space-around">
          <Col sm={8}>
            <p className={styles.linkFont}>Política de privacidad</p>
          </Col>
          <Col sm={8}>
            <p className={styles.linkFont}>Contrato de transporte</p>
          </Col>
          <Col sm={8}>
            <p className={styles.linkFont}>Devoluciones</p>
          </Col>
        </Row> */}
      </Col>
    </Row>
  )
}

export default HelpFooter
