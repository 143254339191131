import React, { FC } from 'react'

import { BackArrowProps } from '../types'

import Arrow from './arrow.blue.inline.svg'
import WhiteArrow from './arrow.white.inline.svg'

import styles from './styles.module.css'

const BackArrow: FC<BackArrowProps> = ({ clickAction, whiteColor }) => (
  <div className={styles.arrow} onClick={clickAction}>
    {whiteColor ? <WhiteArrow /> : <Arrow />}
  </div>
)

export default BackArrow
