import React, { FC, useContext } from 'react'
import { MenuOutlined } from '@ant-design/icons'
import { useLocation } from '@reach/router'
import { Button, Col, Collapse, Drawer, Row } from 'antd'
import { navigate } from 'gatsby'

import { RolesEnum } from '../../../helpers/enums'
import { SessionContext } from '../../../services/session'
import { MenuDrawerProps } from '../../types'
import { adminMenu, linkMenu } from '../constant'
import ProvisionalLogo from '../provisionalLogo.inline.svg'

import styles from './styles.module.css'

const MenuDrawer: FC<MenuDrawerProps> = ({ drawerVisible, onClose }) => {
  const { user } = useContext(SessionContext)
  const location = useLocation()
  return (
    <Drawer
      width="90%"
      title={
        <Row justify="space-between" align="middle">
          <Col span={12}>
            <ProvisionalLogo />
          </Col>
          <Col>
            <Button type="primary" onClick={onClose}>
              <MenuOutlined className={styles.menuIcon} />
            </Button>
          </Col>
        </Row>
      }
      placement="left"
      closable={false}
      onClose={onClose}
      visible={drawerVisible}
      getContainer={false}>
      {linkMenu.map(({ name, path, roles }, idx) =>
        !path && roles?.includes(user.role as RolesEnum) ? (
          <Collapse
            key={idx}
            ghost
            defaultActiveKey={
              adminMenu.map(menu => menu.path).includes(location?.pathname)
                ? 1
                : 0
            }>
            <Collapse.Panel header={name} key={1}>
              {adminMenu.map(({ name, path, type }, idx) =>
                type.split(',').includes(user.role) ? (
                  <Row key={`${path}${idx}`}>
                    <Col push={1} className={styles.margin1}>
                      <a
                        onClick={() => navigate(path)}
                        className={`${styles.linkMargin} ${
                          location?.pathname === path
                            ? styles.linkMobileActive
                            : styles.linkMobile
                        }`}>
                        {name}
                      </a>
                    </Col>
                  </Row>
                ) : null,
              )}
            </Collapse.Panel>
          </Collapse>
        ) : !roles || (roles && roles.includes(user.role as RolesEnum)) ? (
          <Row key={idx}>
            <Col className={styles.margin1}>
              <a
                key={path}
                className={`${styles.linkMargin} ${
                  location?.pathname === path
                    ? styles.linkMobileActive
                    : styles.linkMobile
                }`}
                onClick={() => {
                  navigate(path)
                  onClose()
                }}>
                {name}
              </a>
            </Col>
          </Row>
        ) : null,
      )}
    </Drawer>
  )
}

export default MenuDrawer
