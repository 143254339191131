import React, { ReactElement, useContext } from 'react'
import { Button, Card, Col, Row } from 'antd'
import { Link, navigate } from 'gatsby'
import QRCode from 'qrcode.react';

import styles from './styles.module.css'
import { SessionContext } from '../../../services/session';

const DirectSupport = (): ReactElement => {

const { user } = useContext(SessionContext)

const emailSupport = 'contacto.simplus@simplus.cl'

const handleEmailClick = () => {
  window.location.href = `mailto:${emailSupport}`;
};

const WhatsAppQRCode = () => {
  const phoneNumber = '+56957446748'
  const message = 'Necesito Soporte'

  const whatsappLink = `https://wa.me/${phoneNumber}/?text=${encodeURIComponent(message)}`

  return (
    <div>
      <h3 className={styles.titleFont}>Escanea el código QR para abrir una conversación de Whatsapp con Soporte</h3>
      <h4 className={styles.trackHelpFont}>No se reciben llamadas</h4>
      <QRCode value={whatsappLink} />
    </div>
  );
};

  return (
    <Card className={styles.card}>
      <Row>
        <Col xs={24}>
          <p className={styles.titleFont}>Soporte directo</p>
          <p className={styles.descriptionFont}>
            ¿Tienes algún comentario o no encontraste lo que buscabas? Envíanos
            tus comentarios.
          </p>
          <Row className={styles.buttonContainer}>
            <Col xs={24} sm={24}>
              <Button
                className={styles.sendTicketButton}
                onClick={() => navigate('/app/ayuda/crear-ticket')}>
                Enviar reclamo o sugerencia
              </Button>
              <Button
                className={styles.contactButton}
                onClick={() => {
                  navigate('/app/ayuda/contacto')
                }}>
                Contactar a un ejecutivo
              </Button>
              <p></p>

            {(user.role === 'SCC' || user.role === 'ADMIN_SCC') && (
              <Button
                className={styles.contactButton}
                onClick={handleEmailClick}
                >
                Escribe un correo
              </Button>
            )}

            </Col>
          </Row>
          <p className={styles.trackHelpFont}>
            ¿Ya enviaste un comentario? puedes darle seguimiento{' '}
            <Link to={'/app/mis-solicitudes'}>aquí</Link>.
          </p>
          {(user.role === 'SCC' || user.role === 'ADMIN_SCC') && (
            <div style={{ textAlign: 'center' }}>
              <WhatsAppQRCode />
            </div>
          )}
        </Col>
      </Row>
    </Card>
  )
}

export default DirectSupport
