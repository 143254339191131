import React, { useContext, useState } from 'react'
import { ApolloQueryResult, useMutation, useQuery } from '@apollo/client'
import { Button, Col, message, Modal, Row } from 'antd'
import Text from 'antd/es/typography/Text'
import { navigate } from 'gatsby'

import { getRedirectUrl } from '../../pages/inicio-sesion/log'
import { tokenInfo } from '../../services/auth'
import { SessionContext } from '../../services/session'
import { Company } from '../../types/company.type'
import LoadingSpinner from '../LoadingSpinner'

import { GET_USER_COMPANIES, LOGIN_WITH_SELECTED_COMPANY } from './queries'

import styles from './styles.module.css'

export interface CompanySelectionModalProps {
  selectionModal: boolean
  setSelectionModal: (value: boolean) => void
  loadProfile: () => void
  getCompanyInformation: (options: any) => void
  getPercentDiscount: () => void
}

const CompanySelectionModal: React.FC<CompanySelectionModalProps> = ({
  selectionModal,
  setSelectionModal,
  loadProfile,
  getCompanyInformation,
  getPercentDiscount,
}) => {
  const { setToken, setUser } = useContext(SessionContext)
  const [userCompanies, setUserCompanies] = useState<Company[]>([])
  const { loading } = useQuery(GET_USER_COMPANIES, {
    onCompleted: ({ getUserCompanies }) => setUserCompanies(getUserCompanies),
    onError: error => console.log(error),
  })
  const [loginWithSelectedCompany, { loading: loginLoading }] = useMutation(
    LOGIN_WITH_SELECTED_COMPANY,
  )

  const onCardClick = async (companyId: string) => {
    try {
      const { data } = await loginWithSelectedCompany({
        variables: {
          companyId,
        },
      })
      const { token, user } = tokenInfo(
        data?.loginWithSelectedCompany?.access_token ?? '',
      )
      setUser(user)
      setToken(token)
      setSelectionModal(false)
      loadProfile()
      getCompanyInformation({ variables: { companyId: user.company } })
      getPercentDiscount()
    } catch (err) {
      message.error(err.toString())
    }
  }

  const renderCompanies = () => {
    return (
      <Row>
        {userCompanies.map(({ rut, dv, _id, name }, idx) => (
          <Col span={24} className={styles.colPadding}>
            <Button
              className={styles.companyButton}
              key={idx}
              onClick={() => onCardClick(_id)}>
              <Text className={styles.buttonText}>{name}</Text>
            </Button>
          </Col>
        ))}
      </Row>
    )
  }
  // preguntar si se usa css para poder ocupar flex en ant design
  const onOk = () => setSelectionModal(false)
  return (
    <Modal
      title={null}
      visible={selectionModal}
      onOk={onOk}
      className={styles.companyModal}
      width={'30%'}
      closable={false}
      footer={null}>
      <Row justify="center">
        {loginLoading ? (
          <LoadingSpinner size="100px" />
        ) : (
          <>
            <Col span={24}>
              <Text className={styles.title}>
                Seleccione compania para administrar
              </Text>
            </Col>
            <Col span={24}>
              <div className={styles.dividerBox} />
            </Col>
            <Col span={24} className={styles.scrollableZone}>
              {renderCompanies()}
            </Col>
          </>
        )}
      </Row>
    </Modal>
  )
}

export default CompanySelectionModal
